<template>
    <div class="VideoInfo">
        <div class="VideoInfo--Content">
            <div class="VideoInfo--Close" v-on:click="close()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.38999 0.414699C0.639775 0.164989 0.978512 0.024709 1.33171 0.024709C1.68491 0.024709 2.02364 0.164989 2.27343 0.414699L7.99167 6.13294L13.7099 0.414699C13.8328 0.28748 13.9798 0.186005 14.1423 0.116197C14.3048 0.0463885 14.4796 0.00964381 14.6564 0.00810693C14.8333 0.00657006 15.0087 0.0402715 15.1724 0.107245C15.3361 0.174219 15.4848 0.273124 15.6099 0.398188C15.7349 0.523252 15.8338 0.671972 15.9008 0.835669C15.9678 0.999366 16.0015 1.17476 15.9999 1.35162C15.9984 1.52849 15.9617 1.70327 15.8919 1.86578C15.8221 2.02829 15.7206 2.17526 15.5934 2.29814L9.87511 8.01638L15.5934 13.7346C15.836 13.9858 15.9702 14.3223 15.9672 14.6716C15.9642 15.0208 15.8241 15.3549 15.5771 15.6018C15.3302 15.8488 14.9961 15.9889 14.6468 15.9919C14.2976 15.995 13.9611 15.8607 13.7099 15.6181L7.99167 9.89982L2.27343 15.6181C2.02221 15.8607 1.68575 15.995 1.3365 15.9919C0.987258 15.9889 0.653177 15.8488 0.406215 15.6018C0.159253 15.3549 0.0191681 15.0208 0.0161333 14.6716C0.0130984 14.3223 0.147356 13.9858 0.38999 13.7346L6.10824 8.01638L0.38999 2.29814C0.14028 2.04835 0 1.70961 0 1.35642C0 1.00322 0.14028 0.664484 0.38999 0.414699Z"
                        fill="black" />
                </svg>
            </div>
            <div class="VideoInfo--Title">{{ title }}</div>
            <VideoPlayer v-if="video != undefined" class="VideoInfo--Video" ref="videoPlayer" :video="video"
                :hideOption="true" v-on:load-video="listenResizeVideo()" v-on:time-updated="videoTimeUpdated($event)"
                v-on:expand="expandView()" />
            <div class="VideoInfo--Info" :style="{ 'height': infoHeight }">
                <div class="VideoInfo--Tabs">
                    <p v-on:click="selectedTab = 0" :class="{ 'active': selectedTab == 0 }">{{ $t('transcription_text')}}</p>
                    <p v-if="video != undefined" v-on:click="selectedTab = 1" :class="{ 'active': selectedTab == 1 }">{{$t('transcription_notes_text') }}</p>
                    <p v-if="video != undefined" v-on:click="selectedTab = 2" :class="{ 'active': selectedTab == 2 }">{{$t('analysis') }}</p>
                </div>
                <VideoTranscription :transcription="transcriptionList" :currentSeconds="currentSeconds"
                    :status="currentTranscriptionStatus" :speakerNames="currentSpeakerNames" v-if="selectedTab == 0"
                    v-on:click-frame="goToFrame($event)" v-on:update-name="updateName($event)"
                    v-on:download-word="downloadWord($event)" />
                <VideoComments v-if="selectedTab == 1" v-on:click-frame="goToFrame($event)" :comments="comments"
                    :currentSeconds="currentSeconds" />
                <VideoAnalysis v-if="selectedTab == 2" v-on:click-frame="goToFrame($event)" :analysis="analysis" />
            </div>
        </div>
    </div>
</template>
<script>
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router';
import store from "@/store";
import VideoPlayer from '~/components/VideoPlayer/VideoPlayer.vue';
import VideoTranscription from '~/components/VideoPlayer/VideoTranscription.vue';
import VideoComments from '~/components/VideoPlayer/VideoComments.vue';
import VideoInfo from './VideoInfo';
import VideoAnalysis from './VideoAnalysis.vue';
export default {
    name: "VideoInfo",
    components: {
        VideoPlayer,
        VideoTranscription,
        VideoComments,
        VideoInfo,
        VideoAnalysis
    },
    props: {
        title: String,
        video: Object,
        transcription: {
            type: Object,
            default: null
        },
        comments: {
            type: Array,
            default: null,
        },
        analysis: String,
        initialSeconds: {
            type: Number,
            default: 0
        }
    },
    computed: {
        currentTranscription() {
            if (this.transcription == undefined) return []
            return this.transcriptionList.transcriptionLines
        },
        currentTranscriptionStatus() {
            if (this.transcription == undefined) return ""
            return this.transcription.status
        },
        currentSpeakerNames() {
            if (this.transcription == undefined) return []
            return this.transcriptionList.speakerNames
        }
    },
    data() {
        return {
            selectedTab: 0,
            infoHeight: '400px',
            transcriptionList: []
        }
    },
    methods: {
        async fetchTranscription(){
            if (this.transcription != null && this.transcription.transcriptionLines != undefined) {
                for (let transcription of this.transcription.transcriptionLines) {
                    this.transcriptionList.push(transcription);
                }
            }
        },
        async updateName(event){
          let speakerNames = {}
          let data = {
            speakerNames: {}
          }
          for(let csn of this.currentSpeakerNames){
            data.speakerNames[csn.speakerNum] = csn.name
          }
          data.speakerNames[event.speakerNum] = event.name
          await store.dispatch(COMMUNITY_VIDEOCHAT_SET_TRANSCRIPTION_NAME,{activityId: this.$route.params.id, data: data, videoId:this.videos[this.selectedVideo].archiveId})
          .then((data) => {
            this.fetchTranscription()
          });
        },
        setInfoHeight() {
            if (this.$refs.videoPlayer) {
                let paddingHeight = 128
                this.infoHeight = (paddingHeight + this.$refs.videoPlayer.$refs.video.getBoundingClientRect().height) + "px"
            }
        },
        listenResizeVideo() {
            new ResizeObserver(this.setInfoHeight).observe(this.$refs.videoPlayer.$el)
        },
    },
    async mounted() {
        await this.fetchTranscription()
        await this.setInfoHeight()
        this.goToFrame(this.initialSeconds)
    },
    setup(props) {
        let currentSeconds = ref(0)
        let selectedVideo = ref(0)
        const emitter = inject("emitter")
        const videoPlayer = ref(null)
        const route = useRoute()
        const changeSelectedVideo = (index) => {
            emitter.emit("change-selected-video-transcription", index)
        }
        const close = () => {
            emitter.emit("closeUxVideoInfoModal")
        }
        const videoTimeUpdated = (event) => {
            currentSeconds.value = event
        }
        const goToFrame = (seconds) => {
            if (videoPlayer.value == undefined) return
            videoPlayer.value.updateTime(seconds)
        }
        emitter.on("change-selected-video-transcription", (index) => {
            selectedVideo.value = index
            goToFrame(0)
        })
        return { videoTimeUpdated, selectedVideo, changeSelectedVideo, close, goToFrame, currentSeconds, videoPlayer }
    }
};
</script>
<style lang="scss">
.VideoInfo {
    &--Total span {
        color: var(--primary-color);
    }
}
</style>
<style scoped lang="scss">
.VideoInfo {
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px 0px;

    &--Close {
        cursor: pointer;
        width: 56px;
        height: 56px;
        position: absolute;
        right: -20px;
        top: -20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;

        &:hover {
            background: #dc3545;

            svg path {
                fill: white;
            }
        }
    }

    &--Content {
        position: relative;
        display: grid;
        grid-template-areas:
            'videolist videolist'
            'video info'
            'video info';
        gap: 12px;
        background-color: white;
        border-radius: 2%;
        width: 150vh;
        height: 72vh;
    }

    &--VideoList {
        display: flex;
        gap: 10px;
        grid-area: videolist;
    }

    &--VideoTitle {
        display: flex;
        align-items: center;
        gap: 5px;
        height: fit-content;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: var(--primary-color);
        padding: 8px 14px;
        gap: 5px;
        border: 2px solid #EAEAEA;
        border-radius: 100px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        text-transform: capitalize;

        &.selected {
            border: transparent;
            padding: 10px 16px;

            .VideoInfo--Opacity {
                background: var(--primary-color);
            }
        }

        &.selected:hover .VideoInfo--Opacity {
            opacity: 0.2;
        }

        &:hover {
            .VideoInfo--Opacity {
                background: var(--primary-color);
                opacity: 0.1;
            }
        }

        .fa-trash {
            color: #dc3545;
        }
    }

    &--Video {
        grid-area: video;
        margin-left: 10px;
        width: 100vh;
    }

    &--ActivityInfo {
        grid-area: activityinfo;

        h1 {
            font-family: 'Bariol';
            font-weight: 700;
            font-size: 44px;
        }
    }

    &--Total {
        font-family: 'Bariol';
        font-weight: 700;
        font-size: 18px;
    }

    &--Opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        transition: 0.2s;
        z-index: -1;
    }

    &--Title {
        font-family: Bariol;
        font-size: 25px;
        font-weight: 700;
        margin-left: 10px;
        margin-top: 10px;
        text-align: left;
    }

    &--Info {
        padding: 10px;
        grid-area: info;
        background: #F8F8F8;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        height: 400px;
        margin-right: 10px;
        width: 45vh
    }

    &--Tabs {
        display: grid;
        grid-template-columns: 40% 30% 30%;
        background: white;
        padding: 5px;
        position: relative;
        cursor: pointer;

        p {
            font-family: 'Lato';
            font-weight: 900;
            font-size: 12px;
            text-transform: uppercase;
            padding: 7px;
            z-index: 1;
            text-align: center;
            border-radius: 3px;
            color: black;

            &.active {
                background-color:blueviolet;
                color: white;
            }
        }

        span {
            position: absolute;
            width: calc(50% - 5px);
            height: calc(100% - 10px);
            background: var(--primary-color);
            transition: 0.2s;
            top: 5px;
            left: 5px;
            z-index: 0;
            border-radius: 3px;

            &.switch {
                transform: translate(100%);
            }
        }
    }
}
</style>
