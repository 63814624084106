<template>
    <div id="WatFocusVideoViewer">
        <button class="action-button" @click="openUxVideoInfoModal">
            <span class="play-circle"><i class="fas fa-play play-icon"></i></span>
            {{ $t('suite_watfocus_show_recording') }}
        </button>
    </div>
</template>
<script setup>
import { ref, onBeforeMount, onMounted, inject } from 'vue'
import vueApp from '@/main'
import { mount } from '@/utils/mount'
import VideoInfo from '~/components/VideoPlayer/VideoInfo.vue';

const props = defineProps({
    title: String,
    videoImageUrl: {
        type: String,
        default:
            'https://plus.unsplash.com/premium_photo-1683309558738-aa188bd24140?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    },
    video: Object,
    transcription: {
        type: Object,
        default: null
    },
    comments: {
        type: Array,
        default: null,
    },
    analysis: {
        type: String,
        default: ""
    }
})
const emit = defineEmits(['setWatFocusInfoInstance'])
const emitter = inject("emitter")
const videoThumbSrc = ref('')

const generateVideoThumbSrc = (videoUrl) => {
    videoThumbSrc.value = videoUrl
        .replace(
            'communities-videochats.s3-eu-west-1.amazonaws.com',
            'wat-videos.s3.amazonaws.com',
            's3-eu-west-1.amazonaws.com/wat-thumbs'
        )
        .replace('.mp4', '00001.png')
}

onBeforeMount(async () => {
    generateVideoThumbSrc(
        props.video.url
    )
})

onMounted(() => {
    emitter.on("closeUxVideoInfoModal", (event) => {
        document.body.style.overflow = 'auto';
        if (VideoInfo.value != null && VideoInfo.value != undefined) {
            VideoInfo.value.destroy()
            VideoInfo.value = null
        }
    })
})

const openUxVideoInfoModal = () => {
    const { el, vNode, destroy } = mount(VideoInfo, {
        props: {
            title: props.title,
            video: props.video,
            transcription: props.transcription,
            comments: props.comments,
            analysis: props.analysis
        },
        app: vueApp,
    })
    vueApp._container.appendChild(el)
    emit('setWatFocusInfoInstance', {
        el: el,
        vNode: vNode,
        destroy: destroy,
    })
    document.body.style.overflow = 'hidden';
}
</script>
<style lang="scss" scoped>
#WatFocusVideoViewer {
    .action-button {
        background-color: transparent;
        color: black;
        border: none;
        padding: 8px 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: color 0.3s;
        font-weight: bold;
    }

    .play-circle {
        background-color: rgb(153, 211, 83);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }

    .play-icon {
        margin-right: 0px;
        color: white;
    }
}
</style>